import Vue from 'vue'

export default {
  get (endpoint, params = {}) {
    return Vue.axios.get(endpoint, { params })
      .then(({ data }) => data)
      .catch((error) => {
        throw error
      })
  },
  post (endpoint, data, headers = {}) {
    return Vue.axios.post(endpoint, data, { headers })
      .then(({ data }) => data)
      .catch((error) => {
        return { errors: error.response.data }
      })
  }
}
