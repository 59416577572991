<template>
  <div class="sites-list-wrapper">
    <!-- <h2>Select a site from the left sidebar</h2>
    <br>
    <h2>Global Search</h2>
    <p>global search here</p>
    <br>
    <h2>Export Data</h2>
    <p>export view here</p> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'

const methods = {
  ...mapActions({
    getSites: 'sites/get',
    setUserData: 'ux/setUserData'
  })
}

export default {
  name: 'study',
  methods,
  beforeMount () {
    this.getSites().then(() => {
      this.setUserData(this.$auth.user())
      this.$router.push({ name: 'site', params: { site: this.$auth.user().sites[0] } })
      this.loading = false
    }).catch(() => {
      this.$auth.logout()
      this.loading = false
    })
  }
}
</script>

<style>
.sites-list-wrapper {
  text-align: center;
  margin-top: 50px;
}

.site-list-item {
  margin: 20px;
  cursor: pointer;
}

.site-list-item:hover {
  color: #27CAD3;
}
</style>
