import Vue from 'vue'
import Router from 'vue-router'

import {
  Login,
  Export,
  Account,
  Study,
  Site,
  Participant,
  PasswordReset
} from './views'

Vue.use(Router)

const routes = [
  {
    path: '*',
    redirect: '/'
  }, {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      auth: false
    }
  }, {
    path: '/password-reset/:token',
    name: 'password_reset',
    component: PasswordReset,
    meta: {
      auth: false
    }
  }, {
    path: '/export',
    name: 'export',
    component: Export
  }, {
    path: '/account',
    name: 'account',
    component: Account
  }, {
    path: '/',
    name: 'study',
    component: Study
  }, {
    path: '/:site',
    name: 'site',
    component: Site
  },
  {
    path: '/:site/:participant',
    name: 'participant',
    component: Participant
  }
]
// Protect all routes by default
  .map((route) => {
    const meta = typeof route.meta === 'undefined' ? {} : route.meta
    const auth = typeof meta.auth === 'undefined' ? true : meta.auth
    return {
      ...route,
      meta: {
        ...meta,
        auth
      }
    }
  })

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
