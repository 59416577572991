import Vue from 'vue'
import Buefy from 'buefy'
import axios from 'axios'
import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js'

import App from './App'
import router from './router'
import store from './store'
import filters from './filters'
import authConfig from './auth'
import { API_URL } from './constants'
import './app.scss'
// import './registerServiceWorker'

Vue.config.productionTip = false

Vue.axios = axios
Vue.axios.defaults.baseURL = API_URL

Vue.router = router
Vue.use(VueAuth, authConfig)

Vue.use(Buefy, {
  defaultIconPack: 'fal'
})

Object.keys(filters).forEach((name) => {
  const filter = filters[name]
  Vue.filter(name, filter)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
