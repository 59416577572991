<template>
  <div id="account">
    <div class="section">
      <h4 class="title is-4">Account</h4>
    </div>
    <section class="section">
      <div class="buttons">
        <b-button
          type="is-info"
          @click="showModal('change-email')"
          >Change Email</b-button>
        <b-button
          type="is-info"
          @click="showModal('change-password')"
          >Change Password</b-button>
        <b-modal has-modal-card
          :active.sync="isModalActive">
          <component v-if="isModalActive"
            :is="modalForm"
            @close="close">
          </component>
        </b-modal>
      </div>
    </section>
  </div>
</template>

<script>
import { ChangePassword, ChangeEmail } from './components'

const name = 'account'

const methods = {
  showModal (formType) {
    this.isModalActive = true
    this.modalForm = formType
  },
  close () {
    this.isModalActive = false
    this.modalForm = ''
  }
}

const components = {
  ChangePassword,
  ChangeEmail
}

export default {
  name,
  methods,
  components,
  data: () => ({
    isModalActive: false,
    modalForm: ''
  })
}
</script>
