<template>
  <div id="export">
    <div class="section" style="border-bottom: none;">
      <div style="display: flex; justify-content: space-between;">
        <h2>Export</h2>
        <button class="button is-primary"
          @click="isModalActive = true">
          New Export
        </button>
        <b-modal has-modal-card
          :active.sync="isModalActive">
          <new-export v-if="isModalActive" @close="close"></new-export>
        </b-modal>
      </div>

      <b-table v-if="data.length > 0"
        :data="data">

        <b-table-column field="level" label="Level" v-slot="{ row }">
          <span class="capitalize">{{ row.level }}</span>
        </b-table-column>
        <b-table-column field="site" label="Site" v-slot="{ row }">
          {{ (row.site || {}).siteId }}
        </b-table-column>
        <b-table-column field="participant" label="Participant" v-slot="{ row }">
          {{ (row.participant || {}).participantId }}
        </b-table-column>
        <b-table-column field="type" label="Type" v-slot="{ row }">
          {{ row.type }}
        </b-table-column>
        <b-table-column field="file" label="File" v-slot="{ row }">
          <template v-if="row.file">
            <a
              @click="downloadExport($event, row.id)">{{ row.fileNiceName }}</a>
            <span v-if="newExports.includes(row.id)"
                class="has-badge-danger has-badge-inline has-badge-rounded" data-badge="New">
              &nbsp;&nbsp;&nbsp;
            </span>
          </template>
          <template v-else-if="row.empty">Empty File</template>
          <template v-else-if="row.error">Error</template>
          <template v-else>Generating...</template>
        </b-table-column>
        <b-table-column field="created" label="Created" v-slot="{ row }">
          {{ row.created | datetime }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import API from '@/api'
import { EXPORTS_URL, EXPORT_DOWNLOAD_URL } from '@/constants'
import NewExport from './components/NewExport'

const name = 'export'

const components = {
  NewExport
}

const computed = {
  ...mapGetters({
    newExports: 'ux/newExports'
  })
}

const methods = {
  ...mapActions({
    setNewExports: 'ux/setNewExports'
  }),
  downloadCsvData (data) {
    const url = window.URL.createObjectURL(new Blob([data.csv]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', data.filename)
    document.body.appendChild(link)
    link.click()
    link.remove()
  },
  downloadExport (e, id) {
    e.preventDefault()
    API.get(EXPORT_DOWNLOAD_URL + id).then((data) => {
      this.downloadCsvData(data)
      this.fetchExports()
    })
  },
  fetchExports () {
    API.get(EXPORTS_URL).then((data) => {
      if (!data.errors) {
        this.data = data.results
        this.setNewExports(data.results.filter(f => f.file && !f.downloaded).map(f => f.id))
      }
    })
  },
  close () {
    this.fetchExports()
    this.isModalActive = false
  }
}

export default {
  name,
  components,
  computed,
  methods,
  data: () => ({
    isModalActive: false,
    form: {
      level: null,
      filename: null
    },
    data: []
  }),
  beforeMount () {
    this.fetchExports()
    this.fetchInterval = setInterval(() => {
      if (this.$auth.check()) {
        this.fetchExports()
      } else {
        clearInterval(this.fetchInterval)
      }
    }, 5000)
  }
}
</script>
