import API from '@/api'
import * as types from './mutation-types'

const namespaced = true

const state = {
  list: []
}

const mutations = {
  [types.PUT_SITES] (state, sites) {
    state.list = sites
  }
}

const actions = {
  get: ({ commit }, params) => {
    return API.get('sites/', { params }).then((data) => {
      commit(types.PUT_SITES, data.results)
      return data.results
    })
  }
}

const getters = {
  list (state) {
    return state.list
  }
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
