<template>
  <div class="key-info-wrapper level">
    <div class="level-item">
      <div class="key-info">
        <p class="heading">Step Goal (per day)</p>
        <p class="title" v-if="item.complianceStepsPerDay">{{ item.complianceStepsPerDay | commas }}</p>
        <p class="title" v-else>N/A</p>
      </div>
    </div>
    <div class="level-item">
      <div class="key-info">
        <p class="heading">Active Minutes (per day)</p>
        <p class="title" v-if="item.complianceMinutesActive">{{ item.complianceMinutesActive | commas }}</p>
        <p class="title" v-else>N/A</p>
      </div>
    </div>
    <div class="level-item">
      <div class="key-info">
        <template v-if="isParticipant">
          <p class="heading">Days Compliant</p>
          <p class="title">{{ compliantStat.compliant }} out of {{ compliantStat.total }}</p>
        </template>
        <template v-else>
          <p class="heading">Participants Compliant</p>
          <p class="title">{{ compliantStat.perc | percentage }} ({{ compliantStat.compliant }} out of {{ compliantStat.total }})</p>
        </template>
        <!-- <div class="perc-container">
          <div class="perc-compliant"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
const props = {
  item: {
    type: Object,
    required: true
  },
  isParticipant: {
    type: Boolean,
    required: false
  },
  compliantStat: {
    type: Object,
    required: false
  }
}

export default {
  name: 'ComplianceThresholds',
  props,
  data: () => ({
    date: null,
    dateRange: {
      start: null,
      end: null
    }
  })
}
</script>

<style>
.key-info-wrapper {
  align-items: flex-start !important;
}

.key-info-wrapper .level-item {
  justify-content: flex-start;
  margin: 0.5rem;
}

.title {
  font-size: 1.6rem !important;
}

.perc-container {
  margin-top: 10px;
  height: 8px;
  width: 100%;
  background-color: #ddd;
}

.perc-compliant {
  height: 100%;
  width: 90%;
  background-color: #27CAD3;
}
</style>
