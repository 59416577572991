<template>
  <div>
    <div id="sites" v-if="site && !loading">
      <template v-if="participants.length === 0">
        <div class="section" style="border-bottom: none;">No participants in this site</div>
      </template>
      <template v-else>
        <bread-crumbs :site="siteId"></bread-crumbs>
        <div class="section">
          <h3 style="padding-bottom: 0;">Compliance Thresholds</h3>
          <compliance-thresholds :item="site" :compliantStat="compliantStat"></compliance-thresholds>
        </div>
        <div class="section">
          <div>
            <div style="display: flex; align-items: center; margin-bottom: 1.5rem;">
              <h3 style="margin-bottom: 0;">Participants</h3>
            </div>
            <b-field grouped style="justify-content: space-between;">
              <b-field label="Search by participant" custom-class="is-small" class="filter-field">
                <input class="searchbar input is-shadowless is-small" type="text" placeholder="Search" v-model="search">
              </b-field>
              <b-field label="Filter by date" custom-class="is-small" class="filter-field">
                <div class="date-filter-field">
                  <b-datepicker
                    ref="startDatePicker"
                    class="datepicker-input"
                    custom-class="is-small"
                    placeholder="Start"
                    :selectable-dates="datesWithData"
                    :focused-date="datesWithData[datesWithData.length - 1]"
                    @focus.capture="datepickerClick('endDatePicker')"
                    v-model="filters.dateRange.start">
                  </b-datepicker>
                  <span class="date-divider"> - </span>
                  <b-datepicker
                    ref="endDatePicker"
                    class="datepicker-input"
                    custom-class="is-small"
                    placeholder="End"
                    :focused-date="datesWithData[datesWithData.length - 1]"
                    :min-date="filters.dateRange.start"
                    :selectable-dates="datesWithData"
                    :disabled="filters.dateRange.start === null"
                    @focus.capture="datepickerClick('startDatePicker')"
                    v-model="filters.dateRange.end">
                  </b-datepicker>
                  <b-icon
                    v-if="filters.dateRange.start || filters.dateRange.end"
                    @click.native="filters.dateRange = { start: null, end: null }"
                    class="datepicker-cancel"
                    type="is-danger"
                    pack="fas"
                    icon="times"
                    size="is-small">
                  </b-icon>
                </div>
              </b-field>
              <b-field label="Compliant" custom-class="is-small" class="filter-field">
                <div class="buttons has-addons">
                  <span @click="filterSelect('compliant', true)" :class="['button is-small', filters.compliant===true ? 'is-primary is-selected' : '']">Yes</span>
                  <span @click="filterSelect('compliant', null)" :class="['button is-small', filters.compliant===null ? 'is-primary is-selected' : '']">Both</span>
                  <span @click="filterSelect('compliant', false)" :class="['button is-small', filters.compliant===false ? 'is-primary is-selected' : '']">No</span>
                </div>
              </b-field>
              <b-field label="Notify" custom-class="is-small" class="filter-field">
                <div class="buttons has-addons">
                  <span @click="filterSelect('shouldNotify', true)" :class="['button is-small', filters.shouldNotify===true ? 'is-primary is-selected' : '']">Yes</span>
                  <span @click="filterSelect('shouldNotify', null)" :class="['button is-small', filters.shouldNotify===null ? 'is-primary is-selected' : '']">Both</span>
                  <span @click="filterSelect('shouldNotify', false)" :class="['button is-small', filters.shouldNotify===false ? 'is-primary is-selected' : '']">No</span>
                </div>
              </b-field>
              <b-field label="Upload" custom-class="is-small" class="filter-field">
                <div class="buttons has-addons">
                  <span @click="filterSelect('shouldUpload', true)" :class="['button is-small', filters.shouldUpload===true ? 'is-primary is-selected' : '']">Yes</span>
                  <span @click="filterSelect('shouldUpload', null)" :class="['button is-small', filters.shouldUpload===null ? 'is-primary is-selected' : '']">Both</span>
                  <span @click="filterSelect('shouldUpload', false)" :class="['button is-small', filters.shouldUpload===false ? 'is-primary is-selected' : '']">No</span>
                </div>
              </b-field>
            </b-field>
            <participants-table
              :data="filteredParticipants"
              :filterDates="filters.dateRange">
            </participants-table>
          </div>
        </div>
      </template>
    </div>
    <div v-if="loading" id="app-loading">
      <b-loading :active="true" :is-full-page="false"></b-loading>
      <span>Loading</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { DataMixin } from '@/mixins'
import { BreadCrumbs, ComplianceThresholds } from '@/components'
import ParticipantsTable from './components/ParticipantsTable'

const mixins = [
  DataMixin
]

const components = {
  BreadCrumbs,
  ParticipantsTable,
  ComplianceThresholds
}

const computed = {
  ...mapGetters({
    participants: 'participants/list',
    stepData: 'stepData/list',
    user: 'ux/user'
  }),
  compliantStat () {
    const stat = {
      compliant: [],
      total: this.participants.length,
      perc: null
    }

    const latestDate = []
    Object.values(this.stepData[this.siteId]).forEach(item => {
      item[item.length - 1] ? latestDate.push(item[item.length - 1]) : ''
    })

    stat.compliant = latestDate.map(participant => {
      return this.isCompliant(participant)
    }).filter(item => item === true).length

    stat.perc = stat.compliant / stat.total

    return stat
  },
  datesWithData () {
    let dates = Object.values(this.stepData[this.siteId]).reduce((a, b) => [...a, ...b], []).map(item => new Date(item.date.replace(/-/g, '\/')))
    dates = [...new Set(dates)]
    dates = dates.sort((a, b) => a - b)
    return dates
  },
  filteredParticipants () {
    let participants = this.participants
    if (this.search) {
      participants = participants.filter(item => {
        return item.participantId.toLowerCase().includes(this.search.toLowerCase())
      })
    }

    if (this.filters.compliant !== null) {
      participants = participants.filter(item => {
        const compliant = this.stepData[this.siteId][item.participantId]
          .map(item => this.isCompliant(item))
        return compliant[compliant.length - 1] === this.filters.compliant
      })
    }

    if (this.filters.shouldNotify !== null) {
      participants = participants.filter(item => this.filters.shouldNotify === item.shouldNotify)
    }

    if (this.filters.shouldUpload !== null) {
      participants = participants.filter(item => this.filters.shouldUpload === item.shouldUpload)
    }

    if (this.filters.dateRange.start && this.filters.dateRange.end) {
      participants = participants.filter(item => {
        const stepData = this.stepData[this.siteId][item.participantId]
          .filter(item => new Date(item.date.replace(/-/g, '\/')) >= this.filters.dateRange.start && new Date(item.date.replace(/-/g, '\/')) <= this.filters.dateRange.end)
        return stepData.length > 0
      })
    }

    return participants
  }
}

const methods = {
  ...mapActions({
    getParticipants: 'participants/get',
    getStepData: 'stepData/get'
  }),
  filterSelect (prop, state) {
    if (this.filters[prop] === state) {
      this.filters[prop] = null
    } else {
      this.filters[prop] = state
    }
  },
  datepickerClick (prop) {
    const classes = Array.from(this.$refs[prop].$el.children[0].classList)
    if (classes.includes('is-active')) {
      this.$refs[prop].toggle()
    }
  }
}

export default {
  name: 'site',
  mixins,
  components,
  computed,
  methods,
  data: () => ({
    loading: false,
    search: '',
    filters: {
      compliant: null,
      shouldNotify: null,
      shouldUpload: null,
      dateRange: {
        start: null,
        end: null
      }
    }
  }),
  beforeMount () {
    if (this.participants.length === 0 || this.participants[0].site !== this.site.id) {
      this.loading = true

      this.getParticipants({ site_id: this.$route.params.site }).then(() => {
        const promises = []
        this.participants.forEach(item => {
          const obj = {
            params: { participant_id: item.participantId, include_data: true },
            id: item.participantId,
            site: this.siteId
          }
          promises.push(this.getStepData(obj))
        })
        Promise.all(promises).then(() => { this.loading = false })
      })
    }
  }
}
</script>

<style>
.filter-field {
  display: block !important;
  padding-right: 10px;
  margin-right: 10px;
}

.date-filter-field {
  display: flex;
  align-items: center;
}

.datepicker-input {
  width: 80px;
}

.date-divider {
  margin: 0 0.5rem;
  font-weight: 500;
}

.datepicker-cancel {
  margin-left: 5px;
  cursor: pointer;
}

.datepicker-cancel:hover {
  opacity: 0.7;
}
</style>
