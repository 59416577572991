<template>
  <div class="box-chart-legend">
    <span class="legend-title">Compliant: </span>
    <div class="legend-item-wrapper">
      <div class="item-label">Yes</div>
      <div class="item-box" style="background-color: #27CAD3;"></div>
    </div>
    <div class="legend-item-wrapper">
      <div class="item-label">No</div>
      <div class="item-box" style="background-color: #FF3860;"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompliantLegend'
}
</script>
