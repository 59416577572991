<template>
  <b-table :data="data" ref="daily-activity-table" paginated per-page="5" :opened-detailed="defaultOpenedDetails"
    @details-open="detailsOpen" detailed detail-key="date" default-sort="date" default-sort-direction="desc"
    :show-detail-icon="true" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
    aria-current-label="Current page" style="margin-top: 1.5rem;" :key="showSidebar">

    <b-table-column field="date" label="Date" sortable v-slot="props">
      <span :id="'table-' + props.row.date">{{ props.row.date | date }}</span>
    </b-table-column>

    <b-table-column field="readyToProcess" label="Compliant" v-slot="props">
      <span class="tag is-success" v-if="isCompliant(props.row)">Yes</span>
      <span class="tag is-danger" v-else>No</span>
    </b-table-column>

    <b-table-column field="readyToProcess" label="State" sortable v-slot="props">
      <span class="tag is-info" v-if="props.row.processed">Processed</span>
      <span class="tag is-light" v-else-if="props.row.readyToProcess">Ready</span>
      <span class="tag is-danger" v-else-if="!props.row.readyToProcess && !props.procesed">Not Ready</span>
    </b-table-column>

    <!-- <b-table-column field="readyToProcess" label="Ready to Process" sortable v-slot="props">
        <span class="tag is-light" v-if="props.row.readyToProcess">Yes</span>
        <span class="tag is-danger" v-else>No</span>
      </b-table-column>

      <b-table-column field="processed" label="Processed" sortable v-slot="props">
        <span class="tag is-success" v-if="props.row.processed">Yes</span>
        <span class="tag is-danger" v-else>No</span>
      </b-table-column> -->

    <b-table-column field="minutesActive" label="Active (min)" sortable v-slot="props">
      {{ props.row.minutesActive | commas }}
    </b-table-column>

    <b-table-column field="stepsPerDay" label="Steps" sortable v-slot="props">
      {{ props.row.stepsPerDay | commas }}
    </b-table-column>

    <b-table-column field="startOfDay" label="Start" v-slot="props">
      {{ props.row.startOfDay | time }}
    </b-table-column>

    <b-table-column field="endOfDay" label="End" v-slot="props">
      {{ props.row.endOfDay | time }}
    </b-table-column>

    <template #detail="props">
      <strong>{{ props.row.name }}</strong>
      <bar-chart :chartName="`daily-activity-${props.row.date}`" :data="dailyActivityData" :timeRange="timeRange"
        yLabel="Steps" xLabel="Time (24 Hour)" :maintainAspectRatio="false" :timeline="true" height="200px">
        <div style="display: flex;" slot="buttons">
          <b-button class="is-small" style="margin-right: 0.5rem;" @click="downloadCsv(props.row.id)">Download
            CSV</b-button>
          <b-button v-if="processAndSend" class="is-small" style="margin-right: 0.5rem;"
            @click="processFile(props.row.id)">Process & Send</b-button>
        </div>
        <daily-activity-legend slot="legend"></daily-activity-legend>
      </bar-chart>
    </template>
  </b-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { DataMixin } from '@/mixins'
import { BarChart, DailyActivityLegend } from '@/components/charts'
import API from '@/api'
import { DAY_CSV_URL } from '@/constants'

const name = 'DailyActivityTable'

const mixins = [
  DataMixin
]

const props = {
  data: {
    type: Array,
    required: true
  },
  dateOpened: {
    type: String,
    required: false
  }
}

const components = {
  BarChart,
  DailyActivityLegend
}

const computed = {
  ...mapGetters({
    showSidebar: 'ux/showSidebar'
  })
}

const methods = {
  detailsOpen($event) {
    this.stepDataDay = $event
    this.minutes = Object.keys($event.data.minutes).length > 0 ? $event.data.minutes : { [$event.startOfDay]: 0, [$event.endOfDay]: 0 }
    this.ranges = $event.data.ranges
  },
  downloadCsvData(data) {
    const url = window.URL.createObjectURL(new Blob([data.csv]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', data.filename + '.csv')
    document.body.appendChild(link)
    link.click()
    link.remove()
  },
  downloadCsv(id) {
    API.get(DAY_CSV_URL + id).then((data) => {
      this.downloadCsvData(data)
    })
  },
  processFile(id) {
    API.get(DAY_CSV_URL + id + '?process=true').then((data) => {
      this.downloadCsvData(data)
    })
  }
}

export default {
  name,
  mixins,
  props,
  components,
  computed,
  methods,
  data: () => ({
    defaultOpenedDetails: [1],
    showDetailIcon: true,
    stepDataDay: null,
    minutes: null,
    processAndSend: false,
  }),
  watch: {
    dateOpened() {
      const row = this.data.find(item => item.date === this.dateOpened)
      this.$refs['daily-activity-table'].toggleDetails(row)
      const top = document.getElementById(`table-${this.dateOpened}`).getBoundingClientRect().top
      window.scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    }
  },
  mounted() {
    this.processAndSend = this.$auth.user().processAndSend
  }
}
</script>

<style>
.b-table .table tr.detail {
  box-shadow: none !important;
}
</style>
