<template>
  <div id="app">
    <template v-if="$auth.ready() && !loading">
      <navbar />
      <div id="content-wrapper">
        <sidebar v-if="$auth.check() && showSidebar" />
        <router-view id="main-content" :style="!$auth.check() || !showSidebar ? 'width: 100%; margin-left: 0;' : ''"
          :key="$route.path" />
      </div>
    </template>
    <div v-else id="app-loading">
      <b-loading :active="true" :is-full-page="false"></b-loading>
      <span>Authenticating</span>
    </div>
  </div>
</template>

<script>
import { SnackbarProgrammatic as Snackbar } from 'buefy'
import { mapGetters, mapActions } from 'vuex'
import API from '@/api'
import { USER_AUTH_REFRESH_URL, USER_DATA_URL } from '@/constants'
import { Navbar, Sidebar } from '@/components/layout'

const components = {
  Navbar,
  Sidebar
}

const computed = {
  ...mapGetters({
    defaultSite: 'ux/defaultSite',
    showSidebar: 'ux/showSidebar',
    participants: 'participants/list'
  }),
  site() {
    return this.$route.params.site
  }
}

const methods = {
  ...mapActions({
    getParticipants: 'participants/get',
    getSites: 'sites/get',
    getStepData: 'stepData/get',
    setUserData: 'ux/setUserData',
    setNewExports: 'ux/setNewExports'
  }),
  async fetchInitialData() {
    this.loading = true
    const token = this.$auth.token()
    // Only fetch user if not just logged in
    if (this.$auth.user() === null || !this.$auth.user.email) {
      const user = await API.get(USER_DATA_URL, { token })
      this.setUserData(user)
      this.$auth.user(user)
    }
    this.timeout = this.$auth.user().timeout * 1000
    this.actionBeenTaken = false
    this.startActivityCheck()
    this.startRefreshTokenTimeout()

    this.getSites().then(() => {
      if (this.$route.name === 'study') {
        this.$router.push({ name: 'site', params: { site: this.defaultSite } })
      }
      if (['site', 'participant'].includes(this.$route.name)) {
        this.getParticipants({ site_id: this.site }).then(() => {
          const promises = []
          if (this.participants) {
            this.participants.forEach(item => {
              const obj = {
                params: { participant_id: item.participantId, include_data: true },
                id: item.participantId,
                site: this.site
              }
              promises.push(this.getStepData(obj))
            })
            Promise.all(promises).then(() => { this.loading = false })
          }
        })
      } else {
        this.loading = false
      }
    }).catch(() => {
      this.$auth.logout()
      this.loading = false
    })
  },
  startActivityCheck() {
    if (this.activityTimeout) {
      clearTimeout(this.activityTimeout)
    }
    this.activityTimeout = setTimeout(() => {
      Snackbar.open({
        message: 'You have been logged out due to inactivity.',
        position: 'is-top',
        duration: 30000
      })
      clearInterval(this.refreshTimeout)
      this.logout()
    }, this.timeout)
  },
  startRefreshTokenTimeout() {
    this.refreshTimeout = setTimeout(() => {
      const token = this.$auth.token()
      if (!token) {
        return
      }
      API.post(USER_AUTH_REFRESH_URL, { token }).then((data) => {
        if (data.token) {
          this.$auth.token(null, data.token)
          this.startRefreshTokenTimeout()
        } else {
          this.logout()
        }
      })
    }, this.timeout - (10 * 1000))

  },
  logout() {
    this.$auth.logout()
    clearTimeout(this.activityTimeout)
    this.initialLoad = false
  }
}

const watch = {
  '$route'() {
    if (this.initialLoad === false && this.$route.meta.auth === true) {
      this.initialLoad = true
      this.fetchInitialData()
    }
    if (this.$auth.user() !== null) {
      this.startActivityCheck()
    }
  }
}

export default {
  components,
  computed,
  methods,
  watch,
  data: () => ({
    loading: false,
    initialLoad: false,
    activityTimeout: null,
    refreshTimeout: null,
    timeout: 900000,
    actionBeenTaken: false,
  }),
  beforeMount() {
    const token = this.$auth.token()
    if (token) {
      this.fetchInitialData()
    }

    setInterval(() => {
      if (this.$auth.ready() && this.$auth.check()) {
        API.get('/exports/', { downloaded: false, has_file: true }).then((data) => {
          if (data.results) {
            this.setNewExports(data.results.map(f => f.id))
          }
        })
      }
    }, 30000)

    function debounce(func, timeout = 300) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    }


    const actionTaken = () => {
      if (this.$auth.user() !== null) {
        this.actionBeenTaken = true
        this.startActivityCheck()
      }

    }

    window.onmousemove = debounce(actionTaken, 500)
    window.onkeypress = debounce(actionTaken, 500)
  }
}
</script>
