import axios from 'axios'
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'

import router from './router'

import {
  USER_AUTH_URL,
  TOKEN_STORAGE_KEY
} from '@/constants'

export default {
  plugins: {
    http: axios,
    router: router
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter
  },
  options: {
    tokenDefaultKey: TOKEN_STORAGE_KEY,
    refreshData: {
      enabled: false
    },
    fetchData: {
      enabled: false
    },
    loginData: {
      url: USER_AUTH_URL,
      redirect: { name: 'study' },
      fetchUser: false
    },
    logoutData: {
      makeRequest: false,
      redirect: { name: 'login' }
    },
    notFoundRedirect: { path: '/' }
  }
}
