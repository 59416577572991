<template>
  <div>
    <div id="participants" v-if="!loading">
      <bread-crumbs :site="siteId" :participant="participantId"></bread-crumbs>
      <div class="section">
        <h3>Compliance Thresholds</h3>
        <compliance-thresholds :item="site" :isParticipant="true" :compliantStat="compliantStat" v-if="hasData"></compliance-thresholds>
      </div>
      <template v-if="hasData">
        <div class="section">
          <div>
            <h3>Steps By Day</h3>
            <bar-chart
              chartName="stepsByDay"
              :data="stepGraphData(siteId, participantId)"
              yLabel="Steps"
              :maintainAspectRatio="false"
              height="300px"
              @chart-click="dateOpened = $event"
              style="margin-top: 2.5rem;">
              <compliant-legend slot="legend"></compliant-legend>
            </bar-chart>
          </div>
        </div>
        <div class="section">
          <div>
            <h3>Daily Activity</h3>
            <daily-activity-table
              :data="getParticipantStepData(siteId, participantId)"
              :dateOpened="dateOpened">
            </daily-activity-table>
          </div>
        </div>
      </template>
      <div class="section" v-else>
        <h4>This participant does not have any data yet</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { DataMixin } from '@/mixins'
import { BreadCrumbs, ComplianceThresholds } from '@/components'
import { BarChart, CompliantLegend } from '@/components/charts'
import DailyActivityTable from './components/DailyActivityTable'

const mixins = [
  DataMixin
]

const components = {
  BarChart,
  CompliantLegend,
  BreadCrumbs,
  DailyActivityTable,
  ComplianceThresholds
}

const computed = {
  ...mapGetters({
    participants: 'participants/list',
    stepData: 'stepData/list'
  }),
  participantId () {
    return this.$route.params.participant
  },
  participant () {
    return this.participants.find(participant => participant.participantId === this.participantId)
  },
  compliantStat () {
    const stepData = this.stepData[this.siteId][this.participantId]

    return {
      compliant: stepData.map(item => this.isCompliant(item)).filter(item => item === true).length,
      total: stepData.length
    }
  },
  hasData () {
    return this.stepData[this.siteId][this.participantId].length > 0
  }
}

const methods = {
  ...mapActions({
    getParticipants: 'participants/get',
    getStepData: 'stepData/get'
  })
}

export default {
  name: 'participant',
  mixins,
  components,
  computed,
  methods,
  data: () => ({
    loading: false,
    dateOpened: null
  })
}
</script>
