<template>
  <div>
    <div :style="`height: ${height};`">
      <canvas :id="chartId"></canvas>
    </div>
    <div class="chart-footer">
      <slot name="legend"></slot>
      <div style="display: flex;">
        <slot name="buttons"></slot>
        <a class="button is-small" style="justify-content: flex-end;" @click="download">Save as image</a>
      </div>
    </div>
  </div>
</template>

<script>
import 'moment-timezone'
import moment from 'moment'
import Chart from 'chart.js'
import 'chartjs-plugin-annotation'
import { saveAs } from 'file-saver'
import { mapGetters } from 'vuex'

const props = {
  chartName: {
    type: String,
    required: true
  },
  data: {
    type: Object,
    required: true
  },
  height: {
    type: String,
    required: false,
    default: 'auto'
  },
  maintainAspectRatio: {
    type: Boolean,
    required: false,
    default: true
  },
  yLabel: {
    type: String,
    required: true
  },
  xLabel: {
    type: String,
    required: false
  },
  timeline: {
    type: Boolean,
    required: false
  },
  timeRange: {
    type: Object,
    required: false
  }
}

const computed = {
  ...mapGetters({
    showSidebar: 'ux/showSidebar',
    sites: 'sites/list'
  }),
  chartId() {
    return 'bar-chart-' + this.chartName
  },
  siteId() {
    return this.$route.params.site
  },
  site() {
    return this.sites.find(site => site.siteId === this.siteId)
  }
}

const methods = {
  createXAxis() {
    if (this.timeline) {
      return [
        {
          scaleLabel: {
            display: this.xLabel,
            labelString: this.xLabel
          },
          type: 'time',
          time: {
            min: this.timeRange.start,
            max: this.timeRange.end,
            stepSize: 60,
            unit: 'minute',
            displayFormats: {
              minute: 'HH',
              hour: 'HH'
            }
          },
          gridLines: {
            display: false
          }
        }
      ]
    } else {
      return [
        {
          scaleLabel: {
            display: this.xLabel,
            labelString: this.xLabel
          },
          gridLines: {
            display: false
          }
        }
      ]
    }
  },
  showCadenceAreas() {
    if (this.timeline) {
      if (this.timeRange?.ranges) {
        const annotations = this.timeRange.ranges.map(({ start, end }) => {
          return [
            {
              drawTime: 'beforeDatasetsDraw',
              type: 'box',
              xScaleID: 'x-axis-0',
              yScaleID: 'y-axis-0',
              xMin: start,
              xMax: end,
              yMin: 0,
              yMax: this.site.lowMidCadenceThreshold,
              backgroundColor: 'rgba(39, 202, 211, 0.1)',
              borderColor: 'rgba(0, 0, 0, 0)'
            },
            {
              drawTime: 'beforeDatasetsDraw',
              type: 'box',
              xScaleID: 'x-axis-0',
              yScaleID: 'y-axis-0',
              xMin: start,
              xMax: end,
              yMin: this.site.lowMidCadenceThreshold,
              yMax: this.site.midHighCadenceThreshold,
              backgroundColor: 'rgba(39, 202, 211, 0.2)',
              borderColor: 'rgba(0, 0, 0, 0)'
            },
            {
              drawTime: 'beforeDatasetsDraw',
              type: 'box',
              xScaleID: 'x-axis-0',
              yScaleID: 'y-axis-0',
              xMin: start,
              xMax: end,
              yMin: this.site.midHighCadenceThreshold,
              yMax: 80,
              backgroundColor: 'rgba(39, 202, 211, 0.3)',
              borderColor: 'rgba(0, 0, 0, 0)'
            }
          ]
        }).flat()
        return { annotations }
      }

      return {
        annotations: [
          {
            drawTime: 'beforeDatasetsDraw',
            type: 'box',
            xScaleID: 'x-axis-0',
            yScaleID: 'y-axis-0',
            xMin: this.timeRange.startOfDay,
            xMax: this.timeRange.endOfDay,
            yMin: 0,
            yMax: this.site.lowMidCadenceThreshold,
            backgroundColor: 'rgba(39, 202, 211, 0.1)',
            borderColor: 'rgba(0, 0, 0, 0)'
          },
          {
            drawTime: 'beforeDatasetsDraw',
            type: 'box',
            xScaleID: 'x-axis-0',
            yScaleID: 'y-axis-0',
            xMin: this.timeRange.startOfDay,
            xMax: this.timeRange.endOfDay,
            yMin: this.site.lowMidCadenceThreshold,
            yMax: this.site.midHighCadenceThreshold,
            backgroundColor: 'rgba(39, 202, 211, 0.2)',
            borderColor: 'rgba(0, 0, 0, 0)'
          },
          {
            drawTime: 'beforeDatasetsDraw',
            type: 'box',
            xScaleID: 'x-axis-0',
            yScaleID: 'y-axis-0',
            xMin: this.timeRange.startOfDay,
            xMax: this.timeRange.endOfDay,
            yMin: this.site.midHighCadenceThreshold,
            yMax: 80,
            backgroundColor: 'rgba(39, 202, 211, 0.3)',
            borderColor: 'rgba(0, 0, 0, 0)'
          }
        ]
      }
    } else {
      return {}
    }
  },
  drawChart() {
    const self = this
    const ctx = document.getElementById(this.chartId)

    /* eslint-disable no-new */
    new Chart(ctx, {
      type: 'bar',
      data: self.data,
      options: {
        barValueSpacing: 20,
        legend: {
          display: false
        },
        tooltips: {
          mode: 'index',
          itemSort: function (a, b) {
            return b.datasetIndex - a.datasetIndex
          },
          displayColors: false,
          callbacks: {
            title: function (tooltipItem, data) {
              return self.timeline ? moment(tooltipItem[0].xLabel).format('YYYY-MM-DD HH:mm:ss') : tooltipItem[0].xLabel[0]
            },
            label: function (tooltipItem, data) {
              const label = []
              if (!self.timeline) {
                label.push(tooltipItem.xLabel[1])
                label.push(tooltipItem.xLabel[2])
              } else {
                label.push(self.$options.filters.commas(tooltipItem.yLabel) + ' Steps')
              }
              return label
            }
          }
        },
        maintainAspectRatio: self.maintainAspectRatio,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 8,
                precision: 0,
                callback: function (value, index, values) {
                  return self.$options.filters.abbrevNumber(value)
                }
              },
              scaleLabel: {
                display: true,
                labelString: self.yLabel
              },
              gridLines: {
                display: !self.timeline
              }
            }
          ],
          xAxes: self.createXAxis()
        },
        annotation: self.showCadenceAreas(),
        onClick: (event, el) => {
          const index = el[0]._index
          const date = self.data.labels[index][0]
          const split = date.split('/')
          const originalDate = `${split[2]}-${split[0]}-${split[1]}`
          this.$emit('chart-click', originalDate)
        }
      }
    })
  },
  download() {
    const canvas = document.getElementById(this.chartId)
    canvas.toBlob(function (blob) {
      saveAs(blob, 'snapshot.png')
    })
  }
}

const watch = {
  showSidebar() {
    this.drawChart()
  }
}

export default {
  name: 'BarChart',
  props,
  computed,
  methods,
  watch,
  mounted() {
    this.drawChart()
  },
  beforeMount() {
    moment.tz.setDefault('UTC')
  }
}
</script>

<style>
.chart-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 1rem 1rem 0 0;
}
</style>
