import API from '@/api'
import * as types from './mutation-types'

const namespaced = true

const state = {
  list: []
}

const mutations = {
  [types.PUT_PARTICIPANTS] (state, participants) {
    state.list = participants
  }
}

const actions = {
  get: ({ commit }, params) => {
    return API.get('participants/', params).then((data) => {
      commit(types.PUT_PARTICIPANTS, data.results)
      return data.results
    })
  }
}

const getters = {
  list (state) {
    return state.list
  }
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
