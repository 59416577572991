<template>
    <nav class="top-nav" :style="!$auth.check() ? 'justify-content: center;' : ''">
        <div class="top-nav-start" v-if="$auth.check()">
          <p class="sidebar-control" @click="toggleSidebar">
            <b-icon icon="arrow-from-right" v-if="showSidebar"></b-icon>
            <b-icon icon="arrow-from-left" v-else></b-icon>
          </p>
        </div>

        <img
          @click="$router.push({ name: 'study' })"
          src="@/assets/modus.png"
          alt="Modus"
          class="mini-logo">

        <div class="top-nav-end" v-if="$auth.check()">
            <b-dropdown
              v-model="menu"
              position="is-bottom-left"
              aria-role="menu">
              <p class="control nav-control" slot="trigger">
                <b-icon icon="cog"></b-icon>
                <span v-if="newExports.length > 0"
                  class="cog-badge has-badge-danger has-badge-inline has-badge-rounded" :data-badge="newExports.length">
                </span>
              </p>

              <b-dropdown-item custom aria-role="menuitem">
                  Logged in as
                  <br>
                  <b v-if="user">{{ user.email }}</b>
              </b-dropdown-item>
              <hr class="dropdown-divider">
              <b-dropdown-item @click="$router.push({ name: 'site', params: { site: defaultSite } })" value="home" aria-role="menuitem">
                <b-icon icon="home"></b-icon>
                Home
              </b-dropdown-item>
              <b-dropdown-item
                @click="$router.push({ name: 'export' })"
                value="export"
                aria-role="menuitem">
                <b-icon icon="file-download"></b-icon>
                <span v-if="newExports.length > 0"
                  class="has-badge-danger has-badge-inline has-badge-rounded" :data-badge="newExports.length">
                  Export&nbsp;
                </span>
                <span v-else>Export</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="user.isSuperuser"
                @click="goToAdmin" value="admin" aria-role="menuitem">
                <b-icon icon="tools"></b-icon>
                Admin
              </b-dropdown-item>
              <hr class="dropdown-divider" aria-role="menuitem">
              <b-dropdown-item @click="$router.push({ name: 'account' })" value="account">
                <b-icon icon="user"></b-icon>
                Account
              </b-dropdown-item>
              <b-dropdown-item @click="$auth.logout()" value="logout" aria-role="menuitem">
                <b-icon icon="sign-out-alt"></b-icon>
                Logout
              </b-dropdown-item>
          </b-dropdown>
        </div>
    </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const computed = {
  ...mapGetters({
    defaultSite: 'ux/defaultSite',
    showSidebar: 'ux/showSidebar',
    user: 'ux/user',
    newExports: 'ux/newExports'
  })
}

const methods = {
  ...mapActions({
    toggleSidebar: 'ux/toggleSidebar'
  }),
  goToAdmin () {
    window.location.href = '/api/admin'
  }
}

export default {
  computed,
  methods,
  data: () => ({
    menu: 'home'
  })
}
</script>

<style>
.top-nav {
  height: 50px;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(0,0,0,0.2);
  margin-bottom: 1.5rem;
}

.top-nav-start,
.top-nav-end {
  display: flex;
  align-items: center;
}

.mini-logo {
  height: 30px;
  cursor: pointer;
}

.mini-logo:hover {
  opacity: 0.8;
}

[data-badge].cog-badge {
  position: absolute;
  top: 10px;
}

.sidebar-control,
.nav-control .icon {
  color: #53565A;
  opacity: 0.7;
  cursor: pointer;
}

.sidebar-control {
  margin-right: 15px;
  padding-top: 1px;
}

.study-name {
  color: #666;
}

.nav-control {
  margin-left: 1rem;
}

.nav-control .icon {
  padding: 20px;
}

.sidebar-control:hover,
.nav-control:hover {
  opacity: 0.6;
}
</style>
