<template>
  <nav class="breadcrumb content-header" aria-label="breadcrumbs">
    <ul>
      <li class="is-active"><a>{{ studyName }}</a></li>
      <li :class="participant ? '' : 'is-active'"><a @click="getRoute('site')" aria-current="page">{{ site }}</a></li>
      <li class="is-active" v-if="participant"><a @click="getRoute('participant')" aria-current="page">{{ participant }}</a></li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

const props = {
  site: {
    type: String,
    required: true
  },
  participant: {
    type: String,
    required: false
  }
}

const computed = {
  ...mapGetters({
    studyName: 'ux/studyName'
  })
}

const methods = {
  getRoute (view) {
    if (view === 'site') {
      this.$router.push({ name: 'site', params: { site: this.site } })
    } else if (view === 'participant') {
      this.$router.push({ name: 'participant', params: { site: this.site, participant: this.participant } })
    } else {
      this.$router.push({ name: 'study' })
    }
  }
}

export default {
  props,
  computed,
  methods
}
</script>

<style>
.breadcrumb {
  padding: 1.5rem 0;
  letter-spacing: 0.05em;
  margin: 0 1.5rem 0 !important;
}

.content-header {
  border-bottom: 0.5px solid rgba(0,0,0,0.2);
}
</style>
