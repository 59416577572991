export const TOKEN_STORAGE_KEY = 'step4_token'
export const STATE_STORAGE_KEY = 'step4_state'
export const API_URL = '/api/v1/app/'
export const USER_AUTH_URL = 'auth/'
export const USER_AUTH_REFRESH_URL = 'auth/refresh/'
export const USER_AUTH_TFA_SETUP_URL = 'auth/tfa-setup/'
export const USER_AUTH_TFA_URL = 'auth/tfa/'
export const USER_AUTH_TOKEN_SMS_URL = 'auth/token/sms/'
export const USER_AUTH_TOKEN_VOICE_URL = 'auth/token/voice/'
export const USER_AUTH_TOKEN_ONE_TOUCH_URL = 'auth/token/onetouch/'
export const USER_AUTH_ONE_TOUCH_STATUS_URL = 'auth/onetouch-status/'
export const USER_DATA_URL = 'auth/data/'
export const DAY_CSV_URL = 'day_csv/'
export const EXPORTS_URL = 'exports/'
export const EXPORT_DOWNLOAD_URL = 'exports/download/'
