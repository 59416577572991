<template>
    <div id="sidebar">
      <div class="sidebar-subtitle">Search by {{ view }}</div>
      <div class="sidebar-search-wrapper">
        <b-input
          class="sidebar-search"
          type="search"
          icon-pack="fal"
          icon="search"
          placeholder="Search"
          v-model="search">
        </b-input>
      </div>
      <sidebar-item
        v-for="(item, index) in filteredObjects"
        :item="item"
        :nameProp="`${view}Id`"
        :key="index">
      </sidebar-item>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import SidebarItem from './sidebar/SidebarItem'

const components = {
  SidebarItem
}

const computed = {
  ...mapGetters({
    participants: 'participants/list',
    sites: 'sites/list'
  }),
  objects () {
    return this.view === 'participant' ? this.participants : this.sites
  },
  filteredObjects () {
    if (!this.search) {
      return this.objects
    }
    return this.objects.filter(item => {
      return item[this.view + 'Id'].toLowerCase().includes(this.search.toLowerCase())
    })
  },
  view () {
    return this.$route.name === 'participant' ? 'participant' : 'site'
  }
}

const methods = {
  sideBarPosition () {
    const sidebar = document.getElementById('sidebar')
    const scrollTop = window.scrollY
    if (sidebar) {
      if (scrollTop > 80 && !sidebar.classList.contains('fixed-sidebar')) {
        sidebar.classList.add('fixed-sidebar')
      }
      if (scrollTop < 80 && sidebar.classList.contains('fixed-sidebar')) {
        sidebar.classList.remove('fixed-sidebar')
      }
    }
  }
}

export default {
  components,
  computed,
  methods,
  data: () => ({
    search: ''
  }),
  mounted () {
    window.addEventListener('scroll', () => {
      this.sideBarPosition()
    })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', () => {
      this.sideBarPosition()
    })
  }
}
</script>

<style>
.fixed-sidebar {
  position: fixed !important;
  top: 0;
}

.sidebar-subtitle {
  font-weight: 300;
  letter-spacing: 0.03em;
  color: rgba(0,0,0,0.5);
  margin-bottom: 0.5rem;
  padding: 0 1rem;
}

.sidebar-label::after {
  content: '';
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  margin-left: 20px;
  flex: 1 0 20px;
}

.sidebar-search-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.sidebar-search {
  display: flex;
  align-items: center;
}

.sidebar-search input {
  box-shadow: none !important;
}

.sidebar-search .icon {
  top: 10% !important;
  font-size: 0.8em;
  padding: 0 1rem;
}
</style>
