<template>
  <div class="reset-wrapper">
    <div class="reset-form">
      <h3 class="title is-4 has-text-centered">{{ title }}</h3>
      <p class="password-info">Your password must be at least 12 characters, with at least one number and special character</p>
      <p class="has-text-danger has-text-centered" style="margin-bottom: 0.5rem;"
        v-if="errors.nonFieldErrors">{{ errors.nonFieldErrors[0] }}</p>
      <!-- Password -->
      <b-field
        :type="errors.password ? 'is-danger' : ''"
        :message="errors.password ? errors.password.join('<br>') : ''">
        <b-input
          type="password"
          placeholder="Password"
          v-model="data.password"
          @keydown.enter.native="reset">
        </b-input>
      </b-field>
      <b-field
        :type="errors.confirmPassword ? 'is-danger' : ''"
        :message="errors.confirmPassword ? errors.confirmPassword.join('<br>') : ''">
        <b-input
          type="password"
          placeholder="Confirm Password"
          v-model="data.confirmPassword"
          @keydown.enter.native="reset">
        </b-input>
      </b-field>

      <!-- Button -->
      <button
        :class="['button', 'is-primary', 'is-fullwidth', loading ? 'is-loading' : '']"
        :disabled="loading"
        @click="reset"
        >Reset</button>
    </div>
  </div>
</template>

<script>
import { SnackbarProgrammatic as Snackbar } from 'buefy'

import API from '@/api'

const name = 'password_reset'

const methods = {
  reset () {
    const errors = {}
    if (this.data.password.trim().length === 0) {
      errors.password = ['This field is required']
    }
    if (this.data.confirmPassword.trim().length === 0) {
      errors.confirmPassword = ['This field is required']
    }
    if (Object.keys(errors).length === 0) {
      if (this.data.password !== this.data.confirmPassword) {
        errors.password = ['Passwords do not match']
      } else if (this.data.password.length < 12) {
        errors.password = ['Please enter a password that is 12 or more characters']
      }
    }
    if (Object.keys(errors).length > 0) {
      this.errors = errors
      return
    }
    this.loading = true
    this.errors = {}
    const path = 'account/set-password/'
    const resetData = {
      password: this.data.password,
      token: this.$route.params.token
    }
    API.post(path, resetData).then((data) => {
      if (data.errors) {
        if (data.errors === 'invalid') {
          // Invalid token, redirect to login
          this.$router.replace({ name: 'login' })
          Snackbaropen({
            message: 'Password reset was unsuccessful.  Please try again.',
            position: 'is-top'
          })
        } else {
          this.errors = data.errors
        }
      } else {
        this.$router.replace({ name: 'login' })
        Snackbaropen({
          message: 'Password reset was successful.  Please log in.',
          position: 'is-top'
        })
      }
      this.loading = false
    })
  }
}

export default {
  name,
  methods,
  data () {
    return {
      title: 'Create Your Password',
      loading: false,
      data: {
        password: '',
        confirmPassword: ''
      },
      errors: {}
    }
  },
  beforeMount () {
    if (this.$route.query.reset) {
      this.title = 'Reset Your Password'
    }
  }
}
</script>

<style>
.reset-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.reset-form {
  width: 100%;
  max-width: 400px;
}
.password-info {
  text-align: center;
  margin-bottom: 1rem;
}
</style>
