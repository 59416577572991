import participants from './participants'
import sites from './sites'
import stepData from './stepData'
import ux from './ux'

export default {
  participants,
  sites,
  stepData,
  ux
}
