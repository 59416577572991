import * as types from './mutation-types'

const namespaced = true

const state = {
  showSidebar: true,
  user: null,
  newExports: []
}

const mutations = {
  [types.TOGGLE_SIDEBAR] (state) {
    state.showSidebar = !state.showSidebar
  },
  [types.SET_USER_DATA] (state, data) {
    state.user = data
  },
  [types.SET_NEW_EXPORTS] (state, ids) {
    state.newExports = ids
  }
}

const actions = {
  toggleSidebar: ({ commit }) => {
    commit(types.TOGGLE_SIDEBAR)
  },
  setUserData: ({ commit }, data) => {
    commit(types.SET_USER_DATA, data)
  },
  setNewExports: ({ commit }, ids) => {
    commit(types.SET_NEW_EXPORTS, ids)
  }
}

const getters = {
  showSidebar (state) {
    return state.showSidebar
  },
  user (state) {
    return state.user
  },
  defaultSite (state) {
    return state.user.sites[0]
  },
  studyName (state) {
    return state.user.studyName
  },
  newExports (state) {
    return state.newExports
  }
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
