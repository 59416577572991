<template>
  <div class="box-chart-legend">
    <span class="legend-title">Cadence Level: </span>
    <div class="legend-item-wrapper">
      <div class="item-label">Low</div>
      <div class="item-box" style="background-color: rgba(39, 202, 211, 0.1);"></div>
    </div>
    <div class="legend-item-wrapper">
      <div class="item-label">Medium</div>
      <div class="item-box" style="background-color: rgba(39, 202, 211, 0.2);"></div>
    </div>
    <div class="legend-item-wrapper">
      <div class="item-label">High</div>
      <div class="item-box" style="background-color: rgba(39, 202, 211, 0.3);"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DailyActivityLegend'
}
</script>
