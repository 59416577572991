<template>
  <div class="login-wrapper">
    <div v-if="token === null" class="login-form">
      <h3 class="title is-4 has-text-centered">Welcome back</h3>
      <p class="subtitle is-6 has-text-centered">Enter your credentials to log in</p>
      <p class="has-text-danger has-text-centered" style="margin-bottom: 0.5rem;"
        v-if="errors.nonFieldErrors">{{ errors.nonFieldErrors[0] }}</p>
      <!-- Email -->
      <b-field
        :type="errors.email ? 'is-danger' : ''"
        :message="errors.email ? errors.email[0] : ''">
        <b-input ref="email" key="email"
          type="email"
          placeholder="Email"
          v-model="loginData.email"
          @keydown.enter.native="login">
        </b-input>
      </b-field>
      <!-- Password -->
      <b-field
        :type="errors.password ? 'is-danger' : ''"
        :message="errors.password ? errors.password[0] : ''">
        <b-input key="email"
          type="password"
          placeholder="Password"
          v-model="loginData.password"
          @keydown.enter.native="login">
        </b-input>
      </b-field>

      <!-- Button -->
      <button
        :class="['button', 'is-primary', 'is-fullwidth', loading ? 'is-loading' : '']"
        :disabled="loading"
        @click="login"
        >Login</button>

      <div id="reset-password" class="has-text-centered">
        <a href="#" @click="open">Click here</a> to reset your password.
        <b-modal has-modal-card :active.sync="isModalActive">
          <reset-password v-if="isModalActive" @close="isModalActive = false" />
        </b-modal>
      </div>
    </div>

    <!-- Save Phone -->
    <div v-else-if="user.authyId === null" class="login-form">
      <h3 class="title is-4 has-text-centered">2-Step Verification Setup</h3>
      <div class="login-info">
        <p>Modus Health Cloud requires 2-step verification to prove it’s really you trying to sign in.</p>
        <p>Please enter your country code and phone number to get started. If you don't have an <a class="authy-link" href="https://authy.com/download/" target="_blank">Authy<sup><img src="@/assets/authy.png"></sup></a> account, basic 2-step verification will use SMS or a phone call.</p>
        <p>To use a One Touch push notification or App-generated One-Time Code for additional security, download and set up <a class="authy-link" href="https://authy.com/download/" target="_blank">Authy<sup><img src="@/assets/authy.png"></sup></a> for free. If you already have an Authy account, your Modus Cloud account will be added automatically.</p>
      </div>
      <p class="has-text-danger has-text-centered" style="margin-bottom: 0.5rem;"
        v-if="tfaSetupError">{{ tfaSetupError }}</p>

      <b-field>
        <b-input key="countryCode"
          placeholder="Country Code"
          v-model="countryCode"
          @keydown.native="numbersOnly"
          @keydown.enter.native="setup">
        </b-input>
      </b-field>

      <b-field>
        <b-input key="phone"
          placeholder="Phone"
          v-model="phone"
          @keydown.native="numbersOnly"
          @keydown.enter.native="setup">
        </b-input>
      </b-field>

      <button
        :class="['button', 'is-primary', 'is-fullwidth', loading ? 'is-loading' : '']"
        :disabled="loading || countryCode.length === 0 || phone.length === 0"
        @click="setup"
        >Setup</button>
    </div>

    <!-- TFA -->
    <div v-else class="login-form">
      <h3 class="title is-4 has-text-centered">2-Step Verification</h3>
      <div class="login-info">
        <p>This extra step shows it’s really you trying to sign in.</p>
        <p class="login-email"><span>{{ user.email }}</span></p>
        <p>To use a One Touch push notification or App-generated One-Time Code for additional security, download and set up <a class="authy-link" href="https://authy.com/download/" target="_blank">Authy<sup><img src="@/assets/authy.png"></sup></a>.</p>
        <p>Otherwise, choose SMS or Phone Call to receive your one-time code via text message or a phone call.</p>
      </div>
      <p class="has-text-danger has-text-centered" style="margin-bottom: 0.5rem;"
        v-if="tfaError">{{ tfaError }}</p>

      <div class="verify-options">
        <button class="button is-info" @click="sendSMS">SMS</button>
        <button class="button is-info" @click="callVoice">Voice</button>
        <button class="button is-info" @click="oneTouch">One Touch</button>
      </div>

      <b-field :type="codeError ? 'is-danger' : ''" :message="codeError">
        <b-input
          key="code"
          type="text"
          placeholder="Code"
          v-model="code"
          @keydown.enter.native="verify">
        </b-input>
      </b-field>

      <button
        :class="['button', 'is-primary', 'is-fullwidth', loading ? 'is-loading' : '']"
        :disabled="loading || code.length === 0"
        @click="verify"
        >Verify</button>
    </div>
  </div>
</template>

<script>
import { SnackbarProgrammatic as Snackbar } from 'buefy'
import { mapActions } from 'vuex'

import API from '@/api'
import {
  USER_AUTH_URL,
  USER_AUTH_TFA_URL,
  USER_AUTH_TFA_SETUP_URL,
  USER_AUTH_TOKEN_SMS_URL,
  USER_AUTH_TOKEN_VOICE_URL,
  USER_AUTH_TOKEN_ONE_TOUCH_URL,
  USER_AUTH_ONE_TOUCH_STATUS_URL
} from '@/constants'
import { ResetPassword } from '../account/components'

const name = 'login'

const methods = {
  ...mapActions({
    setUserData: 'ux/setUserData',
  }),
  open (e) {
    e.preventDefault()
    this.isModalActive = true
  },
  async finalizeLogin (user, token) {
    this.setUserData(user)
    await this.$auth.user(user)
    await this.$auth.token(null, token)
    this.$auth.remember.call(this.$auth, true)
    // this.$auth.watch.authenticated = true
    // this.$auth.watch.loaded = true
    if (this.$auth.redirect()) {
      this.$router.push(this.$auth.redirect().from.path)
    } else if (this.$route.params.fromPath) {
      this.$router.push(this.$route.params.fromPath)
    } else {
      this.$router.push({ name: 'study' })
    }
  },
  login () {
    this.loading = true
    API.post(USER_AUTH_URL, {
      email: (this.loginData.email || '').toLowerCase(),
      password: this.loginData.password,
    }).then((data) => {
      this.loading = false
      if (data.errors) {
        this.errors = data.errors
      } else if (data.tfa !== true) {
        this.finalizeLogin(data.user, data.token)
      } else {
        this.token = data.token
        this.user = data.user
      }
    })
  },
  setup () {
    if (this.countryCode.length === 0 || this.phone.length === 0) {
      return
    }
    const headers = { Authorization: 'Bearer ' + this.token }
    API.post(USER_AUTH_TFA_SETUP_URL, { countryCode: this.countryCode, phone: this.phone }, headers).then((data) => {
      if (data.errors) {
        this.tfaSetupError = data.errors.phone[0]
      } else {
        this.user = data.user
      }
    })
  },
  numbersOnly (e) {
    const whitelist = [
      'Enter',
      'Tab',
      'Meta',
      'Backspace',
      'ArrowRight',
      'ArrowLeft'
    ]
    if (!isFinite(event.key) && !whitelist.includes(e.key)) {
      e.preventDefault()
    }
  },
  sendSMS () {
    const headers = { Authorization: 'Bearer ' + this.token }
    API.post(USER_AUTH_TOKEN_SMS_URL, {}, headers).then((data) => {
      if (data.errors) {
        this.tfaError = data.errors
      } else {
        Snackbaropen({
          message: 'SMS has been sent to your device',
          position: 'is-top'
        })
      }
    })
  },
  callVoice () {
    const headers = { Authorization: 'Bearer ' + this.token }
    API.post(USER_AUTH_TOKEN_VOICE_URL, {}, headers).then((data) => {
      if (data.errors) {
        this.tfaError = data.errors
      } else {
        Snackbaropen({
          message: 'Your device will be called',
          position: 'is-top'
        })
      }
    })
  },
  oneTouch () {
    const headers = { Authorization: 'Bearer ' + this.token }
    API.post(USER_AUTH_TOKEN_ONE_TOUCH_URL, {}, headers).then((data) => {
      if (data.errors) {
        this.tfaError = data.errors
      } else {
        let tries = 0
        let interval = null
        interval = setInterval(() => {
          if (tries > 30) {
            clearInterval(interval)
            Snackbaropen({
              message: 'The request timed out.  Please try another method',
              position: 'is-top'
            })
          }
          tries++

          API.post(USER_AUTH_ONE_TOUCH_STATUS_URL, { onetouchUuid: data.onetouchUuid }, headers).then((data) => {
            if (data.errors) {
              Snackbaropen({
                message: 'There was a problem with One Touch.  Please try another method',
                position: 'is-top'
              })
              clearInterval(interval)
            } else {
              if (data === 'approved') {
                this.finalizeLogin(this.user, this.token)
                clearInterval(interval)
              } else if (data !== 'pending') {
                Snackbaropen({
                  message: 'One Touch was not approved.  Please try again or use another method',
                  position: 'is-top'
                })
                clearInterval(interval)
              }
            }
          })
        }, 2000)
      }
    })
  },
  verify () {
    this.loading = true
    const headers = { Authorization: 'Bearer ' + this.token }
    API.post(USER_AUTH_TFA_URL, { token: this.code }, headers).then((data) => {
      this.loading = false
      if (data.errors) {
        this.tfaError = data.errors
      } else {
        this.finalizeLogin(this.user, this.token)
      }
    })
  }
}

const components = {
  ResetPassword
}

export default {
  name,
  methods,
  components,
  data () {
    return {
      loading: false,
      loginData: {},
      errors: {},
      isModalActive: false,
      token: null,
      user: null,
      code: '',
      codeError: '',
      tfaError: '',
      countryCode: '',
      phone: '',
      tfaSetupError: ''
    }
  },
  mounted () {
    this.$refs.email.$el.querySelector('input').focus()
  }
}
</script>

<style>
.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.login-form {
  width: 100%;
  max-width: 400px;
}

#reset-password {
  margin-top: 1rem;
}

.verify-options {
  margin-bottom: 1rem;
}

.verify-options button {
  margin-right: 1rem;
}

.login-info p {
  margin-bottom: 1rem;
  line-height: 1.6rem;
}

.authy-link {
  color: #ec1c24;
  white-space: nowrap;
}

.authy-link sup {
  margin-left: 2px;
  vertical-align: bottom;
}

.authy-link img {
  width: 16px;
  margin-bottom: -2px;
}

.login-email {
  text-align: center;
}

.login-email span {
  display: inline-block;
  border-radius: 20px;
  border: 1px solid #dadce0;
  padding: 0.2rem 2rem;
  font-weight: 700;
}
</style>
