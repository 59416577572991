import API from '@/api'
import * as types from './mutation-types'

const namespaced = true

const state = {
  list: {}
}

const mutations = {
  [types.PUT_STEP_DATA] (state, obj) {
    if (!state.list[obj.site]) {
      state.list[obj.site] = {}
    }
    state.list[obj.site][obj.id] = obj.data.sort((a, b) => a.date.localeCompare(b.date))
  }
}

const actions = {
  get: ({ commit }, obj) => {
    return API.get('step_data_days/', obj.params).then((data) => {
      commit(types.PUT_STEP_DATA, { data: data.results, id: obj.id, site: obj.site })
      return data.results
    })
  }
}

const getters = {
  list (state) {
    return state.list
  }
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
