import { SnackbarProgrammatic as Snackbar } from 'buefy'
import { mapGetters } from 'vuex'
import moment from 'moment'

const computed = {
  ...mapGetters({
    sites: 'sites/list'
  }),
  siteId() {
    return this.$route.params.site
  },
  site() {
    const site = this.sites.find(site => site.siteId === this.siteId)
    if (typeof site === 'undefined') {
      const openRoutes = this.$router.options.routes.filter(r => r.meta.auth === false && r.path.includes(this.siteId))
      if (openRoutes.length === 0) {
        Snackbar.open({
          message: `Site with ID "${this.siteId}" not found.`,
          position: 'is-top'
        })
      }
      this.$router.replace({ name: 'study' })
    }
    return site
  },
  complianceStepsPerDay() {
    return this.site.complianceStepsPerDay
  },
  complianceMinutesActive() {
    return this.site.complianceMinutesActive
  },
  dailyActivityData() {
    const data = {
      labels: [],
      datasets: [{
        backgroundColor: '#27CAD3',
        data: []
      }]
    }

    data.labels = Object.keys(this.minutes)

    data.datasets[0].data = Object.values(this.minutes)

    return data
  },
  timeRange() {
    const minute = Object.keys(this.minutes)[0]
    return {
      ranges: this.ranges ? this.ranges : undefined,
      start: moment(minute).format('YYYY-MM-DD 00:00:01'),
      end: moment(minute).format('YYYY-MM-DD 23:59:59'),
      startOfDay: moment(this.stepDataDay.startOfDay).format('YYYY-MM-DD HH:mm:ss'),
      endOfDay: moment(this.stepDataDay.endOfDay).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}

const methods = {
  getParticipantStepData(site, id) {
    return this.stepData[site][id].sort((a, b) => {
      return a.date.localeCompare(b.date)
    })
  },
  stepGraphData(site, id) {
    const participantStepData = this.getParticipantStepData(site, id).slice(-5)

    const data = {
      labels: [],
      datasets: [{
        backgroundColor: [],
        data: [],
        minutesActive: []
      }]
    }

    participantStepData.forEach(item => {
      const stepsPerDay = item.stepsPerDay === 0 ? 0 : this.$options.filters.commas(item.stepsPerDay)
      data.labels.push([
        this.$options.filters.date(item.date),
        'Steps: ' + stepsPerDay + ' / ' + this.$options.filters.commas(this.complianceStepsPerDay),
        'Active (min): ' + item.minutesActive + ' / ' + this.complianceMinutesActive
      ])
    })
    data.datasets[0].backgroundColor = participantStepData.map(item => {
      return this.isCompliant(item) ? '#27CAD3' : '#FF3860'
    })
    data.datasets[0].data = participantStepData.map(item => item.stepsPerDay)
    data.datasets[0].minutesActive = participantStepData.map(item => item.minutesActive)

    return data
  },
  isCompliant(item) {
    return item.stepsPerDay >= this.complianceStepsPerDay && item.minutesActive >= this.complianceMinutesActive
  }
}

export default {
  computed,
  methods
}
