<template>
  <b-table
    :data="data"
    ref="table"
    paginated
    per-page="10"
    :opened-detailed="defaultOpenedDetails"
    @details-open="detailsOpen"
    detailed
    detail-key="participantId"
    :row-class="(row, index) => stepData[siteId][row.participantId].length !== 0 ? '': 'hide-arrow-icon-detail'"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page">
    <b-table-column field="participantId" label="Id" sortable v-slot="props">
      <a @click="$router.push({ name: 'participant', params: { site: $route.params.site, participant: props.row.participantId } })">
        {{ props.row.participantId }}
      </a>
    </b-table-column>

    <b-table-column field="strideLengthInMeters" label="Stride (m)" sortable v-slot="props">
      {{ props.row.strideLengthInMeters.toFixed(2) }}
    </b-table-column>

    <b-table-column field="compliant" label="Compliant" v-slot="props">
      <span class="tag is-warning" v-if="stepData[siteId][props.row.participantId].length === 0">No Data</span>
      <span class="tag is-light" v-else-if="filterDates.end && isOutOfCollectionPeriod(collectionPeriod(props.row))">
        N/A (current collection period not in range)
      </span>
      <span class="tag is-success" v-else-if="isCompliant(collectionPeriod(props.row))">
        Yes as of {{ collectionPeriod(props.row).date | date }}
      </span>
      <span class="tag is-danger" v-else>
        No as of {{ collectionPeriod(props.row).date | date }}
      </span>
    </b-table-column>

    <b-table-column field="shouldNotify" label="Notify" v-slot="props">
      <span class="tag is-light" v-if="filterDates.end && isOutOfCollectionPeriod(collectionPeriod(props.row))">N/A</span>
      <span class="tag is-warning" v-else-if="props.row.shouldNotify">Yes</span>
      <span class="tag is-light" v-else>No</span>
    </b-table-column>

    <b-table-column field="shouldUpload" label="Upload" v-slot="props">
      <span class="tag is-light" v-if="filterDates.end && isOutOfCollectionPeriod(collectionPeriod(props.row))">N/A</span>
      <span class="tag is-success" v-else-if="props.row.shouldUpload">Yes</span>
      <span class="tag is-light" v-else>No</span>
    </b-table-column>

    <template #detail="props">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li :class="!chartViewDate ? 'is-active' : ''" @click="chartViewDate = null"><a>Steps Per Day</a></li>
          <li class="is-active" v-if="chartViewDate"><a>{{ chartViewDate | date }}</a></li>
        </ul>
      </nav>
      <bar-chart
        v-if="!chartViewDate"
        chartName="stepsByDay"
        :key="participantId"
        :data="stepGraphData(siteId, participantId)"
        :stepDataDay="stepDataDay"
        yLabel="Steps"
        :maintainAspectRatio="false"
        height="200px"
        @chart-click="chartViewDate = $event">
        <compliant-legend slot="legend"></compliant-legend>
      </bar-chart>
      <template v-else>
        <bar-chart
          :chartName="`daily-activity=${props.row.id}`"
          :key="chartViewDate"
          :data="dailyActivityData"
          :timeRange="timeRange"
          yLabel="Steps"
          xLabel="Time (24 Hour)"
          :maintainAspectRatio="false"
          :timeline="true"
          height="200px">
          <daily-activity-legend slot="legend"></daily-activity-legend>
        </bar-chart>
      </template>
      </template>
  </b-table>
</template>

<script>
import { mapGetters } from 'vuex'

import { DataMixin } from '@/mixins'
import { BarChart, CompliantLegend, DailyActivityLegend } from '@/components/charts'

const name = 'ParticipantTable'

const mixins = [
  DataMixin
]

const props = {
  data: {
    type: Array,
    required: true
  },
  filterDates: {
    type: Object,
    required: false
  }
}

const components = {
  BarChart,
  CompliantLegend,
  DailyActivityLegend
}

const computed = {
  ...mapGetters({
    stepData: 'stepData/list'
  }),
  stepDataDay () {
    return this.stepData[this.siteId][this.participantId].find(item => item.date === this.chartViewDate)
  },
  minutes () {
    if (this.stepDataDay) {
      return this.stepDataDay.data.minutes
    }
  }
}

const methods = {
  toggle (row) {
    this.$refs.table.toggleDetails(row)
  },
  detailsOpen (row, index) {
    this.chartViewDate = null
    this.participantId = row.participantId
    this.defaultOpenedDetails = [row.participantId]
  },
  collectionPeriod (row) {
    const data = this.stepData[this.siteId][row.participantId]
    return data[data.length - 1]
  },
  isOutOfCollectionPeriod (item) {
    const date = new Date(item.date.replace(/-/g, '\/'))
    const lessThan = date < this.filterDates.start
    const greaterThan = date > this.filterDates.end
    return this.filterDates.end && lessThan || greaterThan
  }
}

export default {
  name,
  mixins,
  props,
  components,
  computed,
  methods,
  data: () => ({
    defaultOpenedDetails: [1],
    showDetailIcon: true,
    chartViewDate: null,
    participantId: null
  })
}
</script>

<style>
/* .chevron-cell .icon {
  color: #666;
} */

.b-table .table tr.detail {
  box-shadow: none !important;
}

.hide-arrow-icon-detail a[role='button'] {
  display: none;
}
</style>
