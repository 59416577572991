<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title is-5">{{ title }}</p>
    </header>
    <section class="modal-card-body">
      <slot name="default"></slot>
    </section>
      <footer class="modal-card-foot">
        <b-button class="button" type="primary" @click="$emit('close')">Close</b-button>
        <slot name="footer-buttons"></slot>
      </footer>
    </div>
</template>

<script>
const name = 'modal_form'

const props = {
  title: {
    type: String,
    required: true
  }
}

export default {
  name,
  props
}
</script>

<style>
.modal-card {
  text-align: left;
  min-width: 400px;
}
</style>
