<template>
  <modal-form title="Change Password" v-on="$listeners">
    <b-field
      label="Old Password"
      :type="errors['oldPassword'] ? 'is-danger' : ''"
      :message="errors.oldPassword">
      <b-input ref="oldPassword"
        type="password"
        v-model="formData.oldPassword"
        @keydown.native.enter="changePassword" />
    </b-field>
    <b-field
      label="New Password"
      :type="errors['newPassword1'] ? 'is-danger' : ''"
      :message="errors.newPassword1">
      <b-input
        type="password"
        v-model="formData.newPassword1"
        @keydown.native.enter="changePassword" />
    </b-field>
    <b-field
      label="Repeat New Password"
      :type="errors['newPassword2'] ? 'is-danger' : ''"
      :message="errors.newPassword2">
      <b-input
        type="password"
        v-model="formData.newPassword2"
        @keydown.native.enter="changePassword" />
    </b-field>
    <b-button
      slot="footer-buttons"
      type="is-primary"
      @click="changePassword"
      :loading="loading"
      >Change Password</b-button>
  </modal-form>
</template>

<script>
import { SnackbarProgrammatic as Snackbar } from 'buefy'

import API from '@/api'
import ModalForm from '@/components/ModalForm'
import { TOKEN_STORAGE_KEY } from '@/constants'

const name = 'change_password'

const methods = {
  changePassword () {
    this.loading = true
    this.errors = {}
    const path = 'account/change-password/'
    API.post(path, this.formData).then((data) => {
      if (data.errors) {
        this.errors = data.errors
      } else {
        this.$auth.logout()
        Snackbaropen({
          message: 'Your password has been changed. Please log in again.',
          position: 'is-top'
        })
      }
      this.loading = false
    })
  }
}

const components = {
  ModalForm
}

export default {
  name,
  methods,
  components,
  data: () => ({
    loading: false,
    errors: {},
    formData: {
      oldPassword: '',
      newPassword1: '',
      newPassword2: ''
    }
  }),
  mounted () {
    this.$refs.oldPassword.$el.querySelector('input').focus()
  }
}
</script>

<style>

</style>
