<template>
  <modal-form title="New Export" v-on="$listeners">
    <p class="has-text-danger has-text-centered" style="margin-bottom: 0.5rem;"
      v-if="errors.nonFieldErrors">{{ errors.nonFieldErrors.join('<br>') }}</p>
    <b-field label="Type">
      <b-select placeholder="" v-model="formData.type">
        <option value="steps">Steps</option>
        <option value="sessions">PT Sessions</option>
      </b-select>
    </b-field>
    <b-field label="Level"
      :message="errors.level"
      :type="errors.level ? 'is-danger' : ''">
      <b-select placeholder="Select a level" v-model="formData.level" @input="levelChanged">
        <option value="project">Project</option>
        <option value="site">Site</option>
        <option value="participant">Participant</option>
      </b-select>
    </b-field>
    <b-field v-if="formData.level === 'site'"
      label="Site"
      :message="errors.site"
      :type="errors.site ? 'is-danger' : ''">
      <b-select v-model="formData.site">
        <option v-for="site in [{id: '', siteId: ''}, ...sites]"
          :key="site.id"
          :value="site.id">
          {{ site.siteId }}
        </option>
      </b-select>
    </b-field>
    <b-field v-if="formData.level === 'participant'"
      label="Participant"
      :message="errors.participant"
      :type="errors.participant ? 'is-danger' : ''">
      <b-autocomplete class="participant-dropdown"
        placeholder="Type to Search..."
        :loading="searchLoading"
        :data="participants"
        field="participantId"
        @typing="searchParticipants"
        @select="selectParticipant">
      </b-autocomplete>
    </b-field>
    <b-field label="Filename"
      :message="errors.filename"
      :type="errors.filename ? 'is-danger' : ''">
      <b-input v-model="formData.filename"></b-input>
    </b-field>
    <b-button
      slot="footer-buttons"
      type="is-primary"
      @click="createExport"
      :loading="loading"
      >Create Export</b-button>
  </modal-form>
</template>

<script>
import API from '@/api'
import { mapGetters } from 'vuex'
import ModalForm from '@/components/ModalForm'

const name = 'new_export'

const computed = {
  ...mapGetters({
    sites: 'sites/list'
  })
}

const methods = {
  levelChanged () {
    delete this.formData.site
    delete this.formData.participant
    if (this.formData.level === 'project') {
      this.formData.filename = this.projectFilename
    } else {
      this.formData.filename = this.idFilename
    }
  },
  searchParticipants (search) {
    if (!search.trim()) {
      return
    }
    if (this.searchDebounce !== null) {
      clearTimeout(this.searchDebounce)
    }
    this.searchDebounce = setTimeout(() => {
      this.searchLoading = true
      API.get('participants', { search, limit: 10 }).then((data) => {
        if (data.results.length > 0) {
          this.participants = data.results
        }
        this.searchLoading = false
      })
    }, 400)
  },
  selectParticipant (participant) {
    if (participant) {
      this.formData.participant = participant.id
    } else {
      delete this.formData.participant
    }
  },
  createExport () {
    this.errors = {}

    if (!this.formData.filename) {
      this.errors.filename = 'This field is required'
    }
    if (this.formData.level === 'site' && !this.formData.site) {
      this.errors.site = 'This field is required'
    } else if (this.formData.level === 'participant' && !this.formData.participant) {
      this.errors.participant = 'This field is required'
    }

    if (Object.keys(this.errors).length === 0) {
      this.loading = true
      const path = 'exports/'
      API.post(path, this.formData).then((data) => {
        if (data.errors) {
          this.errors = data.errors
        } else {
          this.$emit('close')
        }
        this.loading = false
      })
    }
  }
}

const components = {
  ModalForm
}

export default {
  name,
  computed,
  methods,
  components,
  data: () => ({
    loading: false,
    searchDebounce: null,
    searchLoading: false,
    participants: [],
    formData: {
      type: 'steps',
      level: 'project',
      filename: ''
    },
    errors: {},
    projectFilename: '',
    idFilename: ''
  }),
  beforeMount () {
    this.idFilename = this.$auth.user().csvExportFilename
    this.projectFilename = this.idFilename.replace('{id}_', '').replace('_{id}', '')
    this.formData.filename = this.projectFilename
  }
}
</script>

<style>

</style>
