const abbrevNumber = (value) => {
  return Math.abs(Number(value)) >= 1.0e+9

    ? (Math.abs(Number(value)) / 1.0e+9).toFixed(1) + 'B'
    : Math.abs(Number(value)) >= 1.0e+6

      ? (Math.abs(Number(value)) / 1.0e+6).toFixed(1) + 'M'
      : Math.abs(Number(value)) >= 1.0e+3

        ? (Math.abs(Number(value)) / 1.0e+3).toFixed(0) + 'K'
        : Math.abs(Number(value))
}

const capitalize = (value) => {
  if (!value) return ''
  return value.toLowerCase().replace(/(^| )(\w)/g, s => s.toUpperCase())
}

const commas = (x) => {
  if (x == 0) {
    return 0
  }
  if (x && typeof x !== 'undefined') {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

const datetime = (date) => {
  if (typeof date === 'string') {
    const d = new Date(date)
    return `${d.toLocaleString()}`
  }
  return date
}

const date = (date) => {
  if (!date) return ''
  // const split = date.split('-')
  // const newDate = split[1] + '/' + split[2] + '/' + split[0]
  return date
}

const percentage = (value) => {
  if (typeof value === 'number') {
    const pValue = (value * 100).toFixed(1)
    return `${pValue}%`
  }
  return value
}

const percentageRound = (value) => {
  if (typeof value === 'number') {
    const pValue = (value * 100).toString()
    return `${pValue.split('.')[0]}%`
  }
  return value
}

const time = (date) => {
  if (typeof date === 'string') {
    const d = new Date(date)
    const split = d.toLocaleString().split(', ')
    return split[1]
  }
  return date
}

export default {
  abbrevNumber,
  capitalize,
  commas,
  datetime,
  date,
  percentage,
  percentageRound,
  time
}
