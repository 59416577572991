<template>
  <b-collapse class="sidebar-card card is-shadowless" :style="isSelected ? '' : ''">
    <div class="card-header is-shadowless">
      <!-- <a class="card-header-icon" @click="open = !open">
        <b-icon
          :style="isSelected ? 'color: #27CAD3' : 'color: rgba(0,0,0,0.5)'"
          :icon="open ? 'chevron-down' : 'chevron-up'">
        </b-icon>
      </a> -->
      <p class="card-header-title" :style="isSelected ? 'color: #27CAD3' : ''" @click="getRoute">
      {{ item[nameProp] }}
      </p>
    </div>
    <div class="card-content" style="padding: 0.5rem 1rem;" v-if="open">
      <div class="content">
        Additional info about site here (maybe tabs below to toggle between charts, etc)
      </div>
    </div>
  </b-collapse>
</template>

<script>
const name = 'SidebarItem'

const props = {
  item: {
    type: Object,
    required: true
  },
  nameProp: {
    type: String,
    required: true
  }
}

const computed = {
  siteId () {
    return this.$route.params.site
  },
  isSelected () {
    return this.item[this.nameProp] === this.$route.params[this.nameProp.substring(0, this.nameProp.length - 2)]
  }
}

const methods = {
  getRoute () {
    if (this.nameProp === 'siteId') {
      this.$router.push({ name: 'site', params: { site: this.item[this.nameProp] } })
    } else {
      this.$router.push({ name: 'participant', params: { site: this.siteId, participant: this.item[this.nameProp] } })
    }
  }
}

export default {
  name,
  props,
  computed,
  methods,
  data: () => ({
    open: false
  })
}
</script>

<style>
.sidebar-card {
  cursor: pointer;
  background: none !important;
  padding: 0 1rem;
}

/* .sidebar-card:nth-child(odd) {
  background: rgba(245, 245, 245, 0.4) !important;
} */

.sidebar-card .card-content {
  margin-bottom: 0.5rem;
}

#sidebar .card-header-title {
  font-weight: 400;
  letter-spacing: 0.05em;
}

#sidebar .card-header-title:hover {
  color: #27CAD3 !important;
}

.card-header-icon {
  font-size: 0.6em;
  padding: 0.75rem 0 !important;
}
</style>
