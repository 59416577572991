<template>
  <modal-form title="Change Email" v-on="$listeners">
    <b-field
      label="New Email"
      :type="errors['email'] ? 'is-danger' : ''"
      :message="errors.email">
      <b-input ref="email"
        v-model="formData.email"
        @keydown.native.enter="changeEmail" />
    </b-field>
    <b-button
      slot="footer-buttons"
      type="is-primary"
      @click="changeEmail"
      :loading="loading"
      >Change Email</b-button>
  </modal-form>
</template>

<script>
import { SnackbarProgrammatic as Snackbar } from 'buefy'

import API from '@/api'
import ModalForm from '@/components/ModalForm'

const name = 'change_email'

const methods = {
  changeEmail () {
    this.loading = true
    this.errors = {}
    const path = 'account/change-email/'
    API.post(path, this.formData).then((data) => {
      if (data.errors) {
        this.errors = data.errors
      } else {
        this.$auth.token(null, data.token)
        this.$router.push({ name: 'login' })
        Snackbaropen({
          message: 'Your email has been changed. Please log in again.',
          position: 'is-top'
        })
      }
      this.loading = false
    })
  }
}

const components = {
  ModalForm
}

export default {
  name,
  methods,
  components,
  data: () => ({
    loading: false,
    errors: {},
    formData: {
      email: ''
    }
  }),
  mounted () {
    this.$refs.email.$el.querySelector('input').focus()
  }
}
</script>

<style>

</style>
