<template>
  <modal-form title="Reset Password" v-on="$listeners">
    <b-field
      label="Email"
      :type="errors['email'] ? 'is-danger' : ''"
      :message="errors.email">
      <b-input
        v-model="formData.email"
        @keydown.native.enter="resetPassword" />
    </b-field>
    <b-button
      slot="footer-buttons"
      type="is-primary"
      @click="resetPassword"
      :loading="loading"
      >Reset Password</b-button>
  </modal-form>
</template>

<script>
import { SnackbarProgrammatic as Snackbar } from 'buefy'

import API from '@/api'
import ModalForm from '@/components/ModalForm'

const name = 'reset_password'

const methods = {
  resetPassword () {
    this.loading = true
    this.errors = {}
    const path = 'account/reset-password/'
    API.post(path, this.formData).then((data) => {
      if (data.errors) {
        this.errors = data.errors
      } else {
        this.$emit('close')
        Snackbaropen({
          message: 'Check your email for a password reset link.',
          position: 'is-top'
        })
      }
      this.loading = false
    })
  }
}

const components = {
  ModalForm
}

export default {
  name,
  methods,
  components,
  data: () => ({
    loading: false,
    errors: {},
    formData: {
      email: ''
    }
  })
}
</script>

<style>

</style>
